import React from "react";
import { Link } from "react-router-dom";

import { logout } from "../../utils/firebase";
import { Badge } from "../Badge/Badge";

function SideBarItem({
  to,
  title,
  count = -1,
}: {
  to: string;
  title: string;
  count?: number;
}) {
  return (
    <Link
      to={to}
      className="py-2 flex items-center justify-between hover:text-gray-400"
    >
      {title}
      {count > -1 && (
        <Badge pill palette="indigo">
          {count ?? "?"}
        </Badge>
      )}
    </Link>
  );
}

export function SideBar() {
  return (
    <div className="bg-gray-600 text-gray-100 space-y-0 w-full md:w-64 md:relative md:translate-x-0">
      <Link
        to="/dashboard"
        className="bg-gray-800 text-white flex items-center justify-center py-4 px-3"
      >
        <span className="text-lg">Eurovision Party Admin</span>
      </Link>
      <nav className="mx-4 flex justify-center gap-4 md:block md:space-y-1 ">
        <SideBarItem to="/events" title="Events" />
        <SideBarItem to="/all-songs" title="Songs" />
        <button
          className="py-2 flex items-center hover:text-gray-400 md:mt-2 "
          onClick={logout}
        >
          Logout
        </button>
      </nav>
    </div>
  );
}
