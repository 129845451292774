import React from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

import { db } from "../../utils/firebase";
import { useAdmin } from "../../contexts/AdminContext";
import { countries } from "../../utils/countries";
import { Button } from "../Button/Button";

function SongItem({ song }: { song: any }) {
  return (
    <tr>
      <td className="py-4 whitespace-nowrap text-center">
        <span
          title={countries[song.country]}
          className={`fi fi-${song.country} w-6 h-5`}
        ></span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="text-sm font-medium text-gray-900">{song.artist}</div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{song.title}</div>
      </td>
    </tr>
  );
}

export function ListSongs() {
  const {
    state: { songs },
  } = useAdmin();

  const [title, setTitle] = React.useState<string>("");
  const [artist, setArtist] = React.useState<string>("");
  const [country, setCountry] = React.useState<string>("");
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const isCountryAlreadyAdded = songs?.some(
    (song: any) => song.country === country
  );

  const canAdd =
    title && artist && country && countries[country] && !isCountryAlreadyAdded;
  const countryLookup = countries[country] ?? "";

  const handleAddSong = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!canAdd) return;

    setIsSaving(true);

    const song = {
      country: country.trim(),
      artist: artist.trim(),
      title: title.trim(),
      created: serverTimestamp(),
    };

    await addDoc(collection(db, "songs"), song);
    setArtist("");
    setCountry("");
    setTitle("");

    setIsSaving(false);
  };

  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <h1 className="text-3xl mb-6">All songs</h1>
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <form onSubmit={handleAddSong}>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Country
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Artist
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Title
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {songs?.map((song: any) => (
                  <SongItem song={song} key={song._docId} />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-center">
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-m p-2 w-12 text-center"
                      type="text"
                      maxLength={2}
                      value={country}
                      placeholder="no"
                      onChange={(e) => setCountry(e.target.value)}
                    />
                    {countryLookup && (
                      <span
                        title={countries[country]}
                        className={`fi fi-${country} w-6 h-5 ml-2`}
                      ></span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-m block p-2 w-full"
                      type="text"
                      value={artist}
                      placeholder="Odd Nordstoga"
                      onChange={(e) => setArtist(e.target.value)}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-m block p-2 w-full"
                      type="text"
                      value={title}
                      placeholder="Kveldssong for deg og meg"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} className="px-6 pb-4">
                    <div className="flex justify-end">
                      <Button
                        type="submit"
                        disabled={!canAdd || isSaving}
                        className=" whitespace-nowrap"
                        palette="green"
                      >
                        Add song
                        {countryLookup && (
                          <>
                            {" "}
                            from <span className="mr-2">{countryLookup}</span>
                          </>
                        )}
                      </Button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
}
