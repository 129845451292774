import React from "react";

import { useAdmin } from "../../contexts/AdminContext";
import { Button } from "../Button/Button";
import { Badge } from "../Badge/Badge";

export function TopBar() {
  const {
    state: { event },
  } = useAdmin();

  return (
    <>
      <div className="flex items-center shadow bg-white py-2.5 text-gray-800">
        <div className="flex-1 px-4 text-center flex flex-col md:flex-row gap-2 items-center justify-between h-min-10">
          {event && (
            <>
              <div className="md:flex items-center">
                <p>
                  <b>{event.title}</b>
                  <Button to={`/events/show/${event._docId}`} className="ml-2">
                    View
                  </Button>
                </p>
              </div>
              <Badge className="text-md">
                <b>{event.stage}</b>
              </Badge>
            </>
          )}
          {!event && <p>No active event</p>}
        </div>
      </div>
    </>
  );
}
