import React, { createContext, useContext } from "react";
import { User } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { query, where, collection } from "firebase/firestore";

import { auth, db } from "../utils/firebase";

export const objConverter = {
  toFirestore(data: any) {
    const { _docId, ...rest } = data;
    return { ...rest };
  },
  fromFirestore(snapshot: any, options: any) {
    const data = snapshot.data(options);
    return {
      ...data,
      _docId: snapshot.id,
    };
  },
};

export const eventsRef = collection(db, "events").withConverter(objConverter);
export const partyUsersRef = collection(db, "partyUsers").withConverter(
  objConverter
);
export const partiesRef = collection(db, "parties").withConverter(objConverter);
export const songsRef = collection(db, "songs").withConverter(objConverter);
export const eventSongsRef = collection(db, "eventSongs").withConverter(
  objConverter
);
export const usersRef = collection(db, "users").withConverter(objConverter);

type AdminState = {
  isReady: boolean;
  user?: User | null;
  partyUsers?: any;
  parties?: any;
  event?: any;
  events?: any;
  songs?: any;
  eventSongs?: any;
};

type AdminContext = {
  state: AdminState;
};
const initialState: AdminState = {
  isReady: false,
};

const Context = createContext<AdminContext>({ state: initialState });

function AdminProvider({ children }: { children: React.ReactElement }) {
  const [user, userLoading] = useAuthState(auth, {
    // onUserChanged: async (user) => {
    //   console.log("user changedds?");
    // },
  });

  // Listen for the active event
  const [activeEvents] = useCollectionData(
    query(eventsRef, where("active", "==", true))
  );

  const event =
    activeEvents && activeEvents.length === 1 ? activeEvents[0] : undefined;

  // Get all events
  const [events] = useCollectionData(query(eventsRef));

  // Get all party user
  const [partyUsers] = useCollectionData(query(partyUsersRef));

  // Get all parties
  const [parties] = useCollectionData(query(partiesRef));

  // Get all songs
  const [songs] = useCollectionData(query(songsRef));

  // Get event songs
  const [eventSongs] = useCollectionData(
    query(eventSongsRef, where("eventId", "==", event?._docId ?? "foobar"))
  );

  return (
    <Context.Provider
      value={{
        state: {
          isReady: !userLoading,
          user,
          partyUsers,
          parties,
          event,
          events,
          songs,
          eventSongs,
        },
      }}
    >
      {children}
    </Context.Provider>
  );
}

function useAdmin() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useApp must be used within the AppProvider");
  }

  return context;
}

export { AdminProvider, useAdmin };
