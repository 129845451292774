import React from "react";
import { Outlet } from "react-router-dom";
import { AdminProvider } from "../../contexts/AdminContext";
import { SideBar } from "../SideBar/SideBar";
import { TopBar } from "../TopBar/TopBar";

export function Main() {
  return (
    <AdminProvider>
      <>
        <SideBar />
        <div className="flex-1">
          <TopBar />
          <div className="p-3 md:p-7">
            <Outlet />
          </div>
        </div>
      </>
    </AdminProvider>
  );
}
