import React from "react";
import { doc, where, query, setDoc, serverTimestamp } from "firebase/firestore";
import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";

import { db } from "../../utils/firebase";
import {
  eventSongsRef,
  partyUsersRef,
  partiesRef,
  useAdmin,
  objConverter,
} from "../../contexts/AdminContext";

import { EventSongs } from "../ListEventSongs.tsx/ListEventSongs";
import { ListParties } from "../ListParties/ListParties";
import { Badge } from "../Badge/Badge";
import { Button } from "../Button/Button";

export function User() {
  const {
    state: { parties: allParties },
  } = useAdmin();

  const { userId } = useParams();
  // const [isSaving, setIsSaving] = React.useState<boolean>(false);
  // const [editStage, setEditStage] = React.useState<boolean>(false);
  // const [nextStage, setNextStage] = React.useState<string>("");

  // Get user from firebase on eventId
  const [user] = useDocumentData(
    doc(db, "users", userId || "foobar").withConverter(objConverter)
  );

  // const [songs] = useCollectionData(
  //   query(eventSongsRef, where("eventId", "==", eventId))
  // );

  const [joinedParties] = useCollectionData(
    query(partyUsersRef, where("userId", "==", userId ?? "foobar"))
  );

  // Find parties where user is a member
  const parties = allParties.filter((party: any) => {
    return joinedParties?.find((joinedParty) => {
      return joinedParty.partyId === party._docId;
    });
  });

  return (
    <div>
      <h1 className="text-3xl mb-6">{user?.nickName}</h1>
      <div className="grid grid-cols-2 gap-4 mb-6">
        <ListParties title="Parties joined" parties={parties} />
      </div>
    </div>
  );
}
