import React from "react";

export function Badge({
  children,
  palette = "green",
  pill = false,
  className = "",
}: {
  children: React.ReactNode;
  pill?: boolean;
  className?: string;
  palette?:
    | "green"
    | "red"
    | "blue"
    | "yellow"
    | "indigo"
    | "purple"
    | "pink"
    | "gray"
    | "white"
    | "black";
}) {
  let color = ``;

  switch (palette) {
    case "green":
      color = `bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-200`;
      break;
    case "red":
      color = `bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-200`;
      break;
    case "blue":
      color = `bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-200`;
      break;
    case "yellow":
      color = `bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-200`;
      break;
    case "indigo":
      color = `bg-indigo-100 text-indigo-800 dark:bg-indigo-800 dark:text-indigo-200`;
      break;
    case "purple":
      color = `bg-purple-100 text-purple-800 dark:bg-purple-800 dark:text-purple-200`;
      break;
    case "pink":
      color = `bg-pink-100 text-pink-800 dark:bg-pink-800 dark:text-pink-200`;
      break;
    case "gray":
      color = `bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-200`;
      break;
    case "white":
      color = `bg-white text-gray-800 dark:bg-gray-400 dark:text-gray-800`;
      break;
    case "black":
      color = `bg-black text-gray-800 dark:bg-gray-900 dark:text-gray-300`;
      break;
  }

  const cssClass = pill
    ? `${className} font-medium px-4 py-0.5 rounded-full ${color} text-base uppercase`
    : `${className} font-medium px-4 py-0.5 rounded ${color} text-base uppercase`;

  return <span className={cssClass}>{children}</span>;
}
