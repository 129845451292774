import React from "react";
import { Link } from "react-router-dom";

export function Button({
  children,
  disabled,
  onClick,
  className,
  type = "button",
  to,
  palette = "blue",
}: {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  type?: "button" | "submit" | "reset";
  to?: string;
  palette?:
    | "green"
    | "red"
    | "blue"
    | "yellow"
    | "indigo"
    | "purple"
    | "pink"
    | "gray"
    | "white"
    | "black";
}) {
  let color = ``;

  switch (palette) {
    case "green":
      color = `bg-green-100 text-green-800 dark:bg-green-700 dark:text-green-100 dark:hover:bg-green-500`;
      break;
    case "red":
      color = `bg-red-100 text-red-800 dark:bg-red-700 dark:text-red-100 dark:hover:bg-red-500`;
      break;
    case "blue":
      color = `bg-blue-100 text-blue-800 dark:bg-blue-700 dark:text-blue-100 dark:hover:bg-blue-500`;
      break;
    case "yellow":
      color = `bg-yellow-100 text-yellow-800 dark:bg-yellow-700 dark:text-yellow-100 dark:hover:bg-yellow-500`;
      break;
    case "indigo":
      color = `bg-indigo-100 text-indigo-800 dark:bg-indigo-700 dark:text-indigo-100 dark:hover:bg-indigo-500`;
      break;
    case "purple":
      color = `bg-purple-100 text-purple-800 dark:bg-purple-700 dark:text-purple-100 dark:hover:bg-purple-500`;
      break;
    case "pink":
      color = `bg-pink-100 text-pink-800 dark:bg-pink-700 dark:text-pink-100 dark:hover:bg-pink-500`;
      break;
    case "gray":
      color = `bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-100 dark:hover:bg-gray-500`;
      break;
    case "white":
      color = `bg-white text-gray-800 dark:bg-gray-400 dark:text-gray-800 dark:hover:bg-gray-500`;
      break;
    case "black":
      color = `bg-black text-gray-800 dark:bg-gray-900 dark:text-gray-300 dark:hover:bg-gray-500`;
      break;
  }

  const cssClass = `${color} text-sm hover:bg-blue-500 font-bold py-1 px-3 rounded focus:outline-none focus:shadow-outline disabled:bg-gray-400 disabled:text-white disabled:hover:bg-gray-400 ${
    className || ""
  }`;

  if (to) {
    return (
      <Link className={cssClass} to={to}>
        {children}
      </Link>
    );
  }

  return (
    <button
      className={cssClass}
      type={type}
      disabled={disabled}
      onClick={onClick ? onClick : undefined}
    >
      {children}
    </button>
  );
}
