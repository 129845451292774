import React from "react";
import { Link } from "react-router-dom";

import { useAdmin } from "../../contexts/AdminContext";

export function Dashboard() {
  const {
    state: { event },
  } = useAdmin();

  return (
    <>
      {event && (
        <Link to={`/events/show/${event._docId}`} className="underline">
          Go to active event
        </Link>
      )}
      {!event && <p>There are no active events.</p>}
    </>
  );
}
