import React from "react";
import { doc, where, query, setDoc, serverTimestamp } from "firebase/firestore";
import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";

import { db } from "../../utils/firebase";
import {
  // eventSongsRef,
  partyUsersRef,
  // usersRef,
  // partiesRef,
  // usersRef,
  // useAdmin,
  objConverter,
} from "../../contexts/AdminContext";

import { EventSongs } from "../ListEventSongs.tsx/ListEventSongs";
import { ListParties } from "../ListParties/ListParties";
import { Badge } from "../Badge/Badge";
import { Button } from "../Button/Button";
import { ListUsers } from "../ListUsers/ListUsers";

export function Party() {
  // const {
  //   state: { events },
  // } = useAdmin();

  const { partyId } = useParams();

  // const [isSaving, setIsSaving] = React.useState<boolean>(false);
  // const [editStage, setEditStage] = React.useState<boolean>(false);
  // const [nextStage, setNextStage] = React.useState<string>("");

  // Get event from firebase on eventId
  const [party] = useDocumentData(
    doc(db, "parties", partyId || "foobar").withConverter(objConverter)
  );

  // Get event from firebase on eventId
  const [event] = useDocumentData(
    doc(db, "events", party ? party.eventId : "foobar").withConverter(
      objConverter
    )
  );

  // const [songs] = useCollectionData(
  //   query(eventSongsRef, where("eventId", "==", eventId))
  // );

  // const [parties] = useCollectionData(
  //   query(partiesRef, where("eventId", "==", eventId))
  // );

  const [users] = useCollectionData(
    query(partyUsersRef, where("partyId", "==", partyId || "foobar"))
  );

  const [createdByUser] = useDocumentData(
    doc(db, "users", party?.createdBy || "foobar").withConverter(objConverter)
  );

  console.log({ createdByUser });

  // TODO:
  // Let user change stage of event

  // const handleChangeStage = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();

  //   setIsSaving(true);

  //   if (!event) return;

  //   await setDoc(
  //     doc(db, "events", event._docId),
  //     {
  //       stage: nextStage,
  //       status: "",
  //       updated: serverTimestamp(),
  //     },
  //     {
  //       merge: true,
  //     }
  //   );

  //   setIsSaving(false);
  //   setEditStage(false);
  // };

  // const setActive = async (nextActive: boolean) => {
  //   setIsSaving(true);

  //   if (!event) return;

  //   await setDoc(
  //     doc(db, "events", event._docId),
  //     {
  //       active: nextActive,
  //       updated: serverTimestamp(),
  //     },
  //     {
  //       merge: true,
  //     }
  //   );

  //   setIsSaving(false);
  // };

  // const hasActiveEvent = events.filter((event: any) => event.active).length > 0;

  // if (eventLoading && !event) return null;
  // if (!event) return null;
  // const canActivate = !hasActiveEvent;

  return (
    <div>
      <h1 className="text-3xl mb-6">
        {party?.name} (created by {createdByUser?.nickName})
      </h1>
      <ListUsers title="Users" users={users ?? []} />
    </div>
  );
}
