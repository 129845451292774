import React from "react";
import {
  collection,
  addDoc,
  setDoc,
  doc,
  serverTimestamp,
  query,
  where,
} from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { db } from "../../utils/firebase";
import { useAdmin, eventSongsRef } from "../../contexts/AdminContext";
import { Button } from "../Button/Button";
import { Badge } from "../Badge/Badge";
import { countries } from "../../utils/countries";

function EventSongItem({
  song,
  nextPlacementAt,
}: {
  song: any;
  nextPlacementAt: number;
}) {
  const [editPlacement, setEditPlacement] = React.useState<boolean>(false);
  const [placement, setPlacement] = React.useState<number | null>(
    song.songData?.placement ?? null
  );
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const handleSetPlacement = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSaving(true);

    await setDoc(
      doc(db, "eventSongs", song._docId),
      {
        placement,
        updated: serverTimestamp(),
      },
      {
        merge: true,
      }
    );

    setEditPlacement(false);
    setIsSaving(false);
  };

  const currentPlacement = typeof placement === "number" ? placement : 0;

  return (
    <tr>
      <td className="py-4 px-2 whitespace-nowrap text-center">
        <span className={`fi fi-${song.songData.country} w-6 h-5 mr-2`} />
      </td>
      <td className="py-4 px-2 whitespace-nowrap text-center">
        <span title={`${song.songData.artist} / ${song.songData.title}`}>
          {countries[song.songData.country]}
        </span>
      </td>

      {!editPlacement && (
        <>
          <td className="px-2 py-4 whitespace-nowrap text-center">
            <div className="text-sm text-gray-900">{song.order}</div>
          </td>
          <td className="px-2 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {!song.placement && (
                <Button
                  onClick={() => {
                    setPlacement(song.placement ?? nextPlacementAt);
                    setEditPlacement(true);
                  }}
                >
                  Set
                </Button>
              )}
              {song.placement && (
                <div className="flex space-x-2 items-center">
                  <Badge palette="green" className="text-md">
                    {song.placement ?? "?"}
                  </Badge>
                  <Button
                    onClick={() => {
                      setPlacement(song.placement ?? nextPlacementAt);
                      setEditPlacement(true);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              )}
            </div>
          </td>
        </>
      )}

      {editPlacement && (
        <td className="px-2 py-4" colSpan={4}>
          <div>
            <form
              onSubmit={handleSetPlacement}
              className="flex space-x-2 items-center"
            >
              <input
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-m block p-1 w-16 text-center"
                type="number"
                maxLength={2}
                value={currentPlacement}
                onChange={(e) =>
                  setPlacement(parseInt(e.target.value, 10) ?? 0)
                }
              />
              <Button
                disabled={currentPlacement < 1 || isSaving}
                type="submit"
                onClick={() => setEditPlacement(true)}
                palette="green"
              >
                OK
              </Button>
              <Button
                disabled={isSaving}
                type="reset"
                onClick={() => setEditPlacement(false)}
                palette="red"
              >
                Cancel
              </Button>
            </form>
          </div>
        </td>
      )}
    </tr>
  );
}

export function EventSongs({
  title = "",
  eventId,
}: {
  title?: string;
  eventId: string;
}) {
  const {
    state: { songs },
  } = useAdmin();

  const [songId, setSongId] = React.useState<string>("");
  const [isAdding, setIsAdding] = React.useState<boolean>(false);
  const [sortBy, setSortBy] = React.useState<"order" | "placement">("order");

  const [eventSongs] = useCollectionData(
    query(eventSongsRef, where("eventId", "==", eventId)),
    {
      initialValue: [],
    }
  );

  const ordered = eventSongs
    ?.map((event: any) => {
      const song = songs.find((song: any) => song._docId === event.song.id);
      return {
        ...event,
        songData: song,
      };
    })
    .sort((a: any, b: any) => {
      switch (sortBy) {
        case "placement":
          if (!a.placement) {
            return 1;
          }
          if (!b.placement) {
            return -1;
          }
          return a.placement - b.placement;

        case "order":
        default:
          return a.order - b.order;
      }
    });

  // Get the lowest allocated placement
  const lowestAllocatedPlacement = ordered?.reduce((acc: number, curr: any) => {
    if (curr.placement && curr.placement < acc) {
      return curr.placement;
    }
    return acc;
  }, ordered.length + 1);

  // Filter out songs that have been added to event
  const unusedSongs = songs
    .filter((song: any) => {
      return !ordered?.find(
        (eventSong: any) => eventSong.song.id === song._docId
      );
    })
    .sort((a: any, b: any) => {
      const cA = countries[a.country];
      const cB = countries[b.country];
      if (cA === cB) {
        return 0;
      }
      if (cA < cB) {
        return -1;
      }
      return 1;
      // return countries[a.country] - countries[b.country];
    });

  // console.log(unusedSongs);

  const canAddSong = unusedSongs.length > 0 && ordered && songId;

  const handleAddSong = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!canAddSong) return;

    setIsAdding(true);

    // const song = unusedSongs.find((song: any) => song._docId === songId);

    const songDocRef = doc(db, "songs", songId);

    const eventSong = {
      eventId: eventId,
      order: ordered.length + 1,
      // song: "/songs/" + songId,
      song: songDocRef,
      created: serverTimestamp(),
    };

    await addDoc(collection(db, "eventSongs"), eventSong);

    setIsAdding(false);
    setSongId("");
  };

  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        {title && <h2 className="text-2xl mb-6">{title}</h2>}

        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 table-auto">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  &nbsp;
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Country
                </th>
                <th
                  scope="col"
                  className={`${
                    sortBy === "order" ? "underline" : ""
                  } px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer`}
                  onClick={() => setSortBy("order")}
                >
                  Order
                </th>
                <th
                  scope="col"
                  className={`${
                    sortBy === "placement" ? "underline" : ""
                  } px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider  cursor-pointer`}
                  onClick={() => setSortBy("placement")}
                >
                  Placement
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {ordered?.map((song: any) => (
                <EventSongItem
                  key={song._docId}
                  song={song}
                  nextPlacementAt={lowestAllocatedPlacement - 1}
                />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={5}>
                  <form
                    className="flex items-center justify-between px-4 py-2"
                    onSubmit={handleAddSong}
                  >
                    <div></div>
                    <div className="flex space-x-2 items-center">
                      <label htmlFor="song" className="sr-only">
                        Song
                      </label>
                      <select
                        id="song"
                        name="song"
                        value={songId}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-m block p-1 w-64"
                        onChange={(e) => setSongId(e.target.value)}
                      >
                        <option value="">Select an unused song to add</option>
                        {unusedSongs.map((song: any) => (
                          <option key={song._docId} value={song._docId}>
                            {countries[song.country]} / {song.artist} -{" "}
                            {song.title}
                          </option>
                        ))}
                      </select>
                      <Button
                        palette="green"
                        disabled={!canAddSong || isAdding}
                        type="submit"
                      >
                        Add
                      </Button>
                    </div>
                  </form>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

export function ListEventSongs() {
  const {
    state: { event },
  } = useAdmin();

  return (
    <EventSongs title={`Songs in ${event.title}`} eventId={event._docId} />
  );
}
