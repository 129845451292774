import React from "react";
import { Link } from "react-router-dom";

import { useAdmin } from "../../contexts/AdminContext";
import { Badge } from "../Badge/Badge";

function PartyUserItem({ partyUser }: { partyUser: any }) {
  const {
    state: { parties },
  } = useAdmin();

  const party = parties.find(
    (party: any) => party._docId === partyUser.partyId
  );

  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <Link
            to={`/users/show/${partyUser.userId}`}
            className="text-sm font-medium text-gray-900 underline"
          >
            {partyUser.name}
          </Link>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{partyUser.created.seconds}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <Badge palette="yellow">{party.name}</Badge>
      </td>
    </tr>
  );
}

export function ListUsers({
  title = "",
  users,
}: {
  title?: string;
  users: any[];
}) {
  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <h1 className="text-3xl mb-6">
          {title} ({users.length})
        </h1>
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Nickname
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Created
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Party
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {users.map((partyUser: any) => (
                <PartyUserItem key={partyUser._docId} partyUser={partyUser} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
