import React from "react";
import { getAuth, signInWithEmailAndPassword, User } from "firebase/auth";
import { Navigate } from "react-router-dom";

import { Button } from "../Button/Button";

export function Login() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [tempUser, setTempUser] = React.useState<User | undefined>(undefined);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );

  const auth = getAuth();

  const canSubmit = !!email && !!password;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setErrorMessage(undefined);

    if (!canSubmit) return;

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        // const tempUser = userCredential.user;
        setTempUser(userCredential.user);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        // const errorCode = error.code;
        // const errorMessage = error.message;

        // console.warn({ errorCode, errorMessage });
      });
  };

  console.log({ tempUser });

  if (tempUser) {
    console.log("Trying to redirect");
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center w-full min-h-screen bg-gray-100">
        <div className="w-full max-w-md">
          <form
            className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
            onSubmit={handleSubmit}
          >
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="text"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => {
                  setErrorMessage(undefined);
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => {
                  setErrorMessage(undefined);
                  setPassword(e.target.value);
                }}
              />
            </div>
            <div className="flex items-center justify-between">
              <Button disabled={!canSubmit} type="submit">
                Sign In
              </Button>
            </div>
            {errorMessage && (
              <p
                className="bg-red-100 text-red-700 border border-red-300 px-4 py-3 rounded relative mt-6"
                role="alert"
              >
                <strong className="font-bold">Error:</strong>
                <span className="block sm:inline">{errorMessage}</span>
              </p>
            )}
          </form>
        </div>
      </div>
    </>
  );
}
