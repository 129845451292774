import React from "react";
import { doc, where, query, setDoc, serverTimestamp } from "firebase/firestore";
import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";

import { db } from "../../utils/firebase";
import {
  eventSongsRef,
  partyUsersRef,
  partiesRef,
  useAdmin,
  objConverter,
} from "../../contexts/AdminContext";

import { EventSongs } from "../ListEventSongs.tsx/ListEventSongs";
import { ListParties } from "../ListParties/ListParties";
import { Badge } from "../Badge/Badge";
import { Button } from "../Button/Button";

export function Event() {
  const {
    state: { events },
  } = useAdmin();
  const { eventId } = useParams();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [editStage, setEditStage] = React.useState<boolean>(false);
  const [nextStage, setNextStage] = React.useState<string>("");

  // Get event from firebase on eventId
  const [event, eventLoading] = useDocumentData(
    doc(db, "events", eventId || "foobar").withConverter(objConverter)
  );

  const [songs] = useCollectionData(
    query(eventSongsRef, where("eventId", "==", eventId))
  );

  const [parties] = useCollectionData(
    query(partiesRef, where("eventId", "==", eventId))
  );

  const [users] = useCollectionData(
    query(partyUsersRef, where("eventId", "==", eventId))
  );

  // TODO:
  // Let user change stage of event

  const handleChangeStage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsSaving(true);

    if (!event) return;

    await setDoc(
      doc(db, "events", event._docId),
      {
        stage: nextStage,
        status: "", // Clear status
        updated: serverTimestamp(),
      },
      {
        merge: true,
      }
    );

    setIsSaving(false);
    setEditStage(false);
  };

  const setActive = async (nextActive: boolean) => {
    setIsSaving(true);

    if (!event) return;

    await setDoc(
      doc(db, "events", event._docId),
      {
        active: nextActive,
        updated: serverTimestamp(),
      },
      {
        merge: true,
      }
    );

    setIsSaving(false);
  };

  const hasActiveEvent = events.filter((event: any) => event.active).length > 0;

  if (eventLoading && !event) return null;
  if (!event) return null;
  const canActivate = !hasActiveEvent;

  return (
    <div>
      <h1 className="text-3xl mb-6">
        {event.title} ({event._docId})
      </h1>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <div className="text-3xl bg-white p-4 rounded-lg shadow flex justify-between items-center">
          <span className="text-xl md:text-3xl sm:block">Parties</span>
          <span>{parties?.length}</span>
        </div>
        <div className="text-3xl bg-white p-4 rounded-lg shadow flex justify-between items-center">
          <span className="text-xl md:text-3xl sm:block">Users</span>
          <span>{users?.length}</span>
        </div>
        <div className="text-3xl bg-white p-4 rounded-lg shadow flex justify-center items-center">
          <Badge
            pill
            className="text-lg"
            palette={event.active ? "green" : "red"}
          >
            {event.active ? "true" : "false"}
          </Badge>
          {event.active && (
            <Button
              className="ml-2"
              onClick={() => setActive(false)}
              disabled={isSaving}
            >
              Stop
            </Button>
          )}
          {!event.active && (
            <Button
              className="ml-2"
              onClick={() => setActive(true)}
              disabled={!canActivate || isSaving}
            >
              Start
            </Button>
          )}
        </div>
        <div className="text-3xl bg-white col-span-2 space-y-3 p-4 rounded-lg shadow">
          <span className="block text-3xl">Stage:</span>
          <span className="text-lg flex gap-2 items-center">
            {!editStage && (
              <>
                <span>{event.stage}</span>
                <Button
                  className="ml-2"
                  onClick={() => {
                    setNextStage(event.stage);
                    setEditStage(true);
                  }}
                  disabled={isSaving}
                >
                  Change
                </Button>
              </>
            )}
            {editStage && (
              <form
                className="flex items-center gap-2"
                onSubmit={handleChangeStage}
              >
                <select
                  name="stage"
                  value={nextStage}
                  onChange={(e) => setNextStage(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option>-- Choose stage --</option>
                  <option value="pre-contest">Pre contest</option>
                  <option value="score-songs">Score songs</option>
                  <option value="calculate-placement">
                    Calculate placement
                  </option>
                  <option value="guess-placement">Guess placement</option>
                  <option value="announce-results">Announce results</option>
                  <option value="calculate-results">Calculate results</option>
                  <option value="show-results">Show results</option>
                  <option value="after-contest">After contest</option>
                </select>
                <Button disabled={isSaving} type="submit" palette="green">
                  🗸
                </Button>
                <Button
                  disabled={isSaving}
                  type="reset"
                  onClick={() => {
                    setEditStage(false);
                    setNextStage("");
                  }}
                  palette="red"
                >
                  🗙
                </Button>{" "}
              </form>
            )}
          </span>
        </div>
        <div className="col-span-2  bg-white space-y-3 p-4 rounded-lg shadow">
          <span className="block text-3xl">Status:</span>
          <span className="text-lg">{event?.status ?? "none"}</span>
        </div>
      </div>
      <div className="space-y-4 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-4">
        <ListParties title="Parties" parties={parties ?? []} />
        <EventSongs title="Songs" eventId={eventId ?? "foobar"} />
      </div>
    </div>
  );
}
