import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../utils/firebase";

import { Login } from "../Login/Login";
import { Main } from "../Main/Main";

export function App() {
  const [user, userLoading] = useAuthState(auth);

  const isReady = !userLoading;

  if (!isReady) return null;

  return (
    <div className="relative min-h-screen md:flex">
      {!user && <Login />}
      {user && <Main />}
    </div>
  );
}
