import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";

import { App } from "./components/App/App";
import { Dashboard } from "./components/Dashboard/Dashboard";
import { ListSongs } from "./components/Songs/ListSongs";
// import { ListParties } from "./components/Parties/ListParties";
import { Event } from "./components/Events/Event";
import { ListEvents } from "./components/ListEvents/ListEvents";
import { ListEventSongs } from "./components/ListEventSongs.tsx/ListEventSongs";
import { Party } from "./components/Party/Party";
import { User } from "./components/User/User";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Navigate to="/" />,
    // errorElement: <div>Not found</div>,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "events",
        element: <ListEvents />,
      },
      {
        path: "events/show/:eventId",
        element: <Event />,
      },
      {
        path: "songs",
        element: <ListEventSongs />,
      },
      {
        path: "users/show/:userId",
        element: <User />,
      },
      // {
      //   path: "parties",
      //   element: <ListParties />,
      // },
      {
        path: "parties/show/:partyId",
        element: <Party />,
      },
      {
        path: "all-songs",
        element: <ListSongs />,
      },
    ],
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById("root")
);
