import React from "react";
// import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { Link } from "react-router-dom";

import { useAdmin } from "../../contexts/AdminContext";
import { Button } from "../Button/Button";
import { Badge } from "../Badge/Badge";
import { db } from "../../utils/firebase";

function EventItem({ event }: { event: any }) {
  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="text-sm font-medium text-gray-900">
            <Link className="underline" to={`/events/show/${event._docId}`}>
              {event.title}
            </Link>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{event.stage}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <Badge className="text-md" palette={event.active ? "green" : "red"}>
          {event.active ? "True" : "False"}
        </Badge>
      </td>
    </tr>
  );
}

export function ListEvents() {
  const {
    state: { events },
  } = useAdmin();

  const [title, setTitle] = React.useState<string>("");
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const isEventNameUsed = events?.some((event: any) => event.title === title);

  const canAdd = title && !isEventNameUsed;

  const handleAddEvent = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!canAdd) return;

    setIsSaving(true);

    const event = {
      title: title.trim(),
      stage: "pre-contest",
      active: false,
      created: serverTimestamp(),
    };

    await addDoc(collection(db, "events"), event);
    setTitle("");

    setIsSaving(false);
  };

  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <h1 className="text-3xl mb-6">Events</h1>
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <form onSubmit={handleAddEvent}>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Stage
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Active
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {events?.map((event: any) => (
                  <EventItem key={event._docId} event={event} />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-center">
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-m block p-2 -ml-2 w-full"
                      type="text"
                      value={title}
                      placeholder="Grand Final"
                      maxLength={25}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">pre-contest</td>
                  <td className="px-6 py-4 whitespace-nowrap">false</td>
                </tr>
                <tr>
                  <td colSpan={3} className="px-6 pb-4">
                    <div className="flex justify-end">
                      <Button
                        type="submit"
                        disabled={!canAdd || isSaving}
                        className=" whitespace-nowrap"
                        palette="green"
                      >
                        Add event
                      </Button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
}
