import React from "react";
import { query, where } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";

import { useAdmin, partiesRef } from "../../contexts/AdminContext";

function PartyItem({ party }: { party: any }) {
  const {
    state: { partyUsers },
  } = useAdmin();

  const users = partyUsers.filter((user: any) => user.partyId === party._docId);

  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="text-sm font-medium text-gray-900">
            <Link className="underline" to={`/parties/show/${party._docId}`}>
              {party.name}
            </Link>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{party.shortId}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center">
        {users.length}
      </td>
    </tr>
  );
}

export function ListParties({
  title = "",
  parties = [],
}: {
  title?: string;
  // eventId: string;
  parties?: any[];
}) {
  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        {title && <h2 className="text-2xl mb-6">{title}</h2>}

        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  ShortId
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
                >
                  Users
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {parties?.map((party: any) => (
                <PartyItem key={party._docId} party={party} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
